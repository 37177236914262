import React from 'react'
import Layout from '../../components/layout'
import OtherLinks from '../../components/otherLinks/otherLinks'
import LightBox from '../../components/lightbox/lightbox'

import org1 from '../../images/pages/org/Organigramm 1.jpg'
import org2 from '../../images/pages/org/Organigramm 2.jpg'
import stellen1 from '../../images/pages/org/Stellenplan im Excel Start.jpg'
import stellen2 from '../../images/pages/org/Stellenplan im Excel.jpg'
import ulcOrg from '../../images/pages/org/ULC Organisation.jpg'
import ulcRollen from '../../images/pages/org/ULC Rollen.jpg'
import ulcStellenstamm from '../../images/pages/org/ULC Stellenstamm.jpg'

const links = [
    {
        name: 'Organigramm',
        desc: 'Hier kann mit dem Organigramm getestet werden',
        link: 'https://organigramm.abacus.ch/organigramm/'
    },
    {
        name: 'Ingentis',
        desc: 'Hersteller des Organigrammprogrammes welches in Abacus integriert ist',
        link: 'https://www.ingentis.com/de/produkte/org-manager-suite/on-premises/'
    }
]

const overviewImages = [
    {
        index: 0,
        src: ulcOrg,
        title: 'Organisation',
        description: 'Die Organisation wird in einem Baumeditor gepflegt. Organisationseinheiten, Stellen und Mitarbeitende werden mit von - bis Datum verknüpft.'
    },
    {
        index: 1,
        src: ulcRollen,
        title: 'Rollen',
        description: 'Pro Stelle können die Zugriffsrechte im Portal festgelegt werden.'
    },
    {
        index: 2,
        src: ulcStellenstamm,
        title: 'Stellenstamm',
        description: 'Im Stellenstamm werden die Informationen auf der Stelle gepflegt. Es können auch Stellenbeschreibungen auf Basis eines Dossiers hinterlegt werden.'
    },
]

const orgImages = [
    {
        index: 0,
        src: org1,
        title: 'Standardlayout',
        description: 'Abacus Organigramm im Standardlayout'
    },
    {
        index: 1,
        src: org2,
        title: 'Weitere Layouts',
        description: 'Weitere Layouts können wahlweise erstellt werden'
    },
]

const stellenImages = [
    {
        index: 0,
        src: stellen1,
        title: 'Download',
        description: 'Die Auswertung wird direkt aus der Personalverwaltung gestartet.'
    },
    {
        index: 1,
        src: stellen2,
        title: 'Pivot',
        description: 'Die Auswertung kann vom Benutzer über Pivot beliebig erweitert werden.'
    },
]

const org = () => (
    <Layout>
        <section id="organisation" className="section-wrapper">
            <header className="section-header">
                <h3>Abacus Organisation</h3>
            </header>
            <div className="post">
                <h3>Übersicht</h3>
                <p>
                    Die Abbildung der Aufbauorganisation eines Unternehmens ist ein sehr wichtiger Bestandteil eines Personalinformationssystemes.
                    Die Organisationsinformationen können sehr vielseitig verwendet werden wie z.B:
                </p>
                <ul>
                    <li>Unterstellungsverhältnisse Vorgesetzte mit Ihren Mitarbeitenden</li>
                    <li>Es wird die Security festgelegt, wer welche Mitarbeitenden im HR Portal sehen darf</li>
                    <li>Die HR Prozesse greifen laufend auf diese Daten zu (z.B. bei Anträgen)</li>
                    <li>Wer hat welche Rechte im HR Portal</li>
                </ul>
                <br />
                <LightBox images={overviewImages} thumbnailHeight={80} />
            </div>
            <div className="post">
                <h3>Organigramm</h3>
                <p>
                    Auf Basis der Organisations- und Mitarbeiterdaten können mit dem Abacus Modul «Organigramme» Organisationspläne erstellt werden. Abacus liefert 3
                    Standardlayouts aus. Es können weitere Layouts definiert werden (Welche Informationen sollen sichtbar sein, Darstellungen etc.)
                </p>
                <br />
                <LightBox images={orgImages} thumbnailHeight={80} />
            </div>
            <div className="post">
                <h3>Stellenplan</h3>
                <p>
                    Mit dem LEPA Addon «Stellenplan» wertet man aufgrund von Organisation und Zeit die Soll und Ist Prozente aus.
                </p>
                <br />
                <LightBox images={stellenImages} thumbnailHeight={80} />
            </div>

            <OtherLinks links={links} />
        </section>
    </Layout>
)


export default org
